export const seo = {
  url: 'wspolpraca',
  title: {
    pl: 'Współpraca z firmą transportową godną zaufania',
    en: 'Cooperation with a trustworthy transport company',
  },
  desc: {
    pl: 'Rozpocznij współpracę w zakresie transportu i logistyki z doświadczoną firmą TSL. Wyspecjalizowane usługi, dedykowany transport i dopasowanie do klienta.',
    en: 'Start cooperation in the field of transport and logistics with an experienced TSL company. Specialized services, dedicated transport and customization.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Współpraca',
    en: 'Collaboration',
  },
  desc: {
    pl: `Rozpocznij współpracę w zakresie transportu i logistyki z doświadczoną firmą TSL. Wyspecjalizowane usługi, dedykowany transport i dopasowanie do klienta.`,
    en: `Start cooperation in the field of transport and logistics with an experienced TSL company. Specialized services, dedicated transport and customization.`,
  },
}

export const main = {
  title: {
    pl: 'Współpraca z firmą transportową godną zaufania',
    en: 'Cooperation with a trustworthy transport company',
  },
  texts: [
    {
      pl: '<span><strong>Usprawnij działalność firmy</strong> dzięki współpracy z Omida Logistics - topowej marki TSL w Europie. Rozpocznij współpracę w zakresie logistyki i transportu. Stawiamy na doświadczenie, dedykowane usługi transportowe oraz <strong>indywidualne podejście</strong> do wymagań partnera.</span>',
      en: `<span><strong>Improve the company's operations</strong> thanks to cooperation with Omida Logistics - the top TSL brand in Europe. Start cooperation in the field of logistics and transport. We focus on experience, dedicated transport services and <strong>individual approach</strong> to the partner's requirements.</span>`,
    },
    {
      pl: '<span>Wypełnij formularz i zostaw kontakt do siebie. Nasz zespół skontaktuje się z Tobą i dopasuje <strong>ofertę odpowiednią do Twojego produktu</strong>.</span>',
      en: '<span>Fill out the form and leave a contact to yourself. Our team will contact you and match an <strong>offer appropriate to your product</strong>.</span>',
    },
  ],
}
